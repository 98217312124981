import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;

  icon = '';
  noItemsText = 'No items';
  oneItemText = 'One item';
  manyItemsText = '# items';
  constructor() {}

  ngOnInit(): void {

  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
