import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', loadChildren: () =>
  import('../app/pages/home/home.module').then((m) => m.HomeModule), },
  {
    path: 'surveyor',
    loadChildren: () =>
      import('../app/pages/surveyor/surveyor.module').then((m) => m.SurveyorModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('../app/pages/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./pages/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('../app/pages/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'download',
    loadChildren: () =>
      import('../app/pages/download/download.module').then((m) => m.DownloadModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
