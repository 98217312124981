<nav class="navbar navbar-expand-md navbar-light fixed-top">


  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']"><img class="logo" src="../../../assets/images/logo.png">EEGWorks</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" (click)="toggleCollapse()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{'show': !isCollapsed}">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link" (click)="toggleCollapse()">Home</a>
        </li>
        <!-- <li class="nav-item ml-md-5 ml-lg-5">
          <a [routerLink]="['/surveyor']" class="nav-link" (click)="toggleCollapse()">Surveyor</a>
        </li> -->
        <!-- <li class="nav-item ml-md-5 ml-lg-5">
          <a [routerLink]="['/product']" class="nav-link" (click)="toggleCollapse()">Products</a>
        </li> -->
        <li class="nav-item ml-md-5 ml-lg-5">
          <a [routerLink]="['/download']" class="nav-link" (click)="toggleCollapse()">Download</a>
        </li>
        <li class="nav-item ml-md-5 ml-lg-5">
          <a [routerLink]="['/about']" class="nav-link" (click)="toggleCollapse()">About</a>
        </li>
        <!--<li class="nav-item ml-md-5 ml-lg-5 eegworks-shopping-cart">
          <a [routerLink]="['/checkout']" class="nav-link" (click)="toggleCollapse()">
            <cart-summary [icon]="icon" [noItemsText]="noItemsText" [oneItemText]="oneItemText"
              [manyItemsText]="manyItemsText">
            </cart-summary>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
