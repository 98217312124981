
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ShoppingCartModule } from 'ng-shopping-cart';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ShoppingCartModule.forRoot({
      // <-- Add the cart module to your root module
      // itemType: MyCartItemClass, // <-- Configuration is optional
      // serviceType: 'localStorage',
      // serviceOptions: {
      //   storageKey: 'NgShoppingCart',
      //   clearOnError: true,
      // },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
