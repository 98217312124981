<footer>


    <!-- Section heading -->
    <h2 class="text-center mt-5 pt-5">CONTACT US</h2>
    <!-- Grid row -->
    <div class="row  mt-5 ">

      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-6 text-center">
        <ul class="list-unstyled">
          <li>
            <h4>Robert Lawson</h4>
          </li>
          <li>

            <a href="tel:425-765-1324"> <i class="fas fa-phone fa-lg"></i></a>
            <h6>425-765-1324</h6>
          </li>
          <li>
            <a href="mailto:robert@sherab.net"> <i class="fas fa-envelope fa-lg"></i></a>
            <h6>robert@sherab.net</h6>
          </li>
        </ul>
      </div>

      <div class="col-md-6 text-center">
        <ul class="list-unstyled">
          <li>
            <h4>James P. Foley</h4>
          </li>
          <li>
            <a href="tel:503-292-8463"> <i class="fas fa-phone fa-lg"></i></a>
            <h6>503-292-8463</h6>
          </li>
          <li>
            <a href="mailto:foleyjp@eegworks.com"> <i class="fas fa-envelope fa-lg"></i></a>
            <h6>foleyjp@eegworks.com</h6>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->


  </footer>
